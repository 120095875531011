export function menu() {
  
  if(!($('.p-indexMenuCam_bnrItem').length)) {
    $('.p-indexMenuSec_inner--cam').remove();
  }

  $('.p-indexMenuSlids').slick({
    autoplay: true,
    arrows: false,
    fade: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    swipe: false,
    speed: 3000,
    autoplaySpeed: 5000
  });

}
  