export function campagin() {
  
  let camSlideItem = $('.p-indexCampaignSlide_item'),
      secWid = $('.p-indexCampaignSec_inner')
 
  $(window).on('load', ()=>{
    let camSlide = $('.p-indexCampaignSlide'),
        camSlideSpacer = $('.p-indexCampaignSec_spacer')
    
    if(camSlideItem.length >= 4) {
      camSlide.slick({
        autoplay: true,
        //variableWidth:true
        slidesToShow: 3,
        arrows: false,
        responsive: [
          {
            breakpoint: 768, // 768px以下のサイズに適用
            settings: {
              slidesToShow: 1,
              dots: true,
              appendDots: $('.p-indexCampaignSlideDots')
            },
          },
        ],
      });
    }

    if(camSlideItem.length <= 3){
      camSlideSpacer.addClass('noSlide');
      secWid.addClass('noSlide');
      secWid.css({
        'width': '100%',
        'max-width': 1040,
        'margin': '0 auto'
      })
      function spSlide(){
        let sp = window.matchMedia('(max-width: 767px)').matches;
        if(sp){
          camSlide.not('.slick-initialized').slick({
              dots: true,
              arrows: false,
              slidesToShow: 1,
              appendDots: $('.p-indexCampaignSlideDots')
          });
        } else {
          $('.p-indexCampaignSlide.slick-initialized').slick('unslick');
        }
      }
      
      spSlide();
      $(window).on('resize orientationchange', function () {
        spSlide();
      });


    }
    
  })

  $(window).on('load resize', ()=>{

    if(camSlideItem.length >= 4) {
      let winSpace = $(window).width() - 1000,
          winSpaceHalf = winSpace / 2;
      secWid.css({
        'width': 1150 + winSpaceHalf,
      })

    }

  })
}
  