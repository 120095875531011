export function mv() {
  let mvSlids = $('.p-indexMv'),
      mvThumb = $('.p-indexThumb');

  if($('.p-indexThumb_item').length <= 4){
    mvThumb.find('.p-indexThumb_item').eq(0).addClass('p-indexThumb_now');
    mvSlids.slick({
      autoplay: true,
      arrows: false,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "15%"
    });
    mvThumb.slick({
      slidesToShow: $('.p-indexThumb_item').length,
      arrows: true,
    });

    mvSlids.on('afterChange', function(slick, currentSlide,index) {
      mvThumb.find('.p-indexThumb_item').removeClass('slick-current');
      mvThumb.find('.p-indexThumb_item').eq(index).addClass('slick-current');
    });
    $('.p-indexThumb_item').on('click', function(){
      let thumbNum = $(this).index();
      mvSlids.slick('slickGoTo',thumbNum);
    })

  } else if($('.p-indexThumb_item').length >= 5) {
    mvThumb.find('.p-indexThumb_item').css({
      'max-width':'none',
    });
    mvSlids.slick({
      //autoplay: true,
      arrows: false,
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "15%",
      asNavFor: mvThumb
    });
    mvThumb.slick({
      slidesToShow: 4,
      asNavFor: mvSlids,
      arrows: true,
      focusOnSelect: true,
      prevArrow: '<div class="slick-prev slick-prev--thumb"></div>',
      nextArrow: '<div class="slick-next slick-next--thumb"></div>',
    });
    
  }
    
  
}
  